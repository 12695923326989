<template></template>
<script>
import AppConfig from '@/configs/app.config.js'

export default {
  name: 'Dashboard',
  mounted() {
    console.log('Dashboard!!')
    const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
    const Role = LoggedInData?.role
    if(Role==='Admin'){
      this.$router.push({name: 'AdminDashboard'})
    }else
    if(Role==='Manager'){
      this.$router.push({name: 'ManagerDashboard'})
    }else
    if(Role==='Branch'){
      this.$router.push({name: 'BranchDailyRecord'})
    }else{
      this.$router.push({name: 'Login'})
    }
  }
}
</script>
